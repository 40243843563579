import { InteractionSchemaSessionId } from '@/helpers/services/api';

export const constants = {
  backButtonLabel: '<',
  nextButtonLabel: '>',
  similarityLinkLabel: 'Similar Response',
  copy: { linkCopied: 'Link Copied!', copyLinkText: 'Copy Link' },
  sessionIdText: (interactionIdText: InteractionSchemaSessionId | undefined) => `Session ID: ${interactionIdText}`,
  paginationText: (currentPage: number, pageCount: number) => `${currentPage + 1}/${pageCount}`
};
