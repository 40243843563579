import { SearchSchemaFieldNamesItem } from '@/helpers/services/api';

export const INPUT_PROPERTIES = [
  'Text Length',
  'Average Word Length',
  'Max Word Length',
  '% Special Characters',
  'Sentiment',
  'Subjectivity',
  'Toxicity',
  'Fluency',
  'Formality',
  'Reading Ease',
  'Reading Time',
  'English Text',
  'Lexical Density',
  'Unique Noun Count',
  'Average Words Per Sentence',
  'URLs Count',
  'Email Addresses Count',
  'Sentences Count'
];

export const OUTPUT_PROPERTIES = [...INPUT_PROPERTIES, 'Grounded in Context', 'Avoided Answer', 'Relevance'];

export const constants = {
  outputKey: 'Output',
  addColumn: 'Add Column',
  inputKey: 'Input',
  llmKey: 'LLM',
  garakKey: 'Garak',
  customKey: 'Custom',
  columnLimit: 3,
  sampleKey: 'Interaction',
  responseKey: 'Response',
  historyKey: 'History',
  informationRetrievalKey: 'Information Retrieval',
  searchFields: {
    tooltip: 'Click to modify your search fields',
    options: Object.keys(SearchSchemaFieldNamesItem).map(field => {
      return { value: field, label: field };
    })
  },
  fullPromptKey: 'Full Prompt',
  stepNameKey: (id: string, name: string) => `${name} (ID=${id})`,
  stepId: (id: string) => `ID=${id}`,
  dateTitle: 'Date',
  annotation: 'Annotation',
  details: 'Details',
  reason: 'Reason',
  searchFieldPlaceholder: 'Search by field...',
  samplesPageLength: 20,
  selectAllTitle: (nFiltered?: number, nVersion?: number) =>
    `Select all ${nFiltered ? `( ${nFiltered} ${nVersion ? `of ${nVersion} ` : ''})` : ''}`,
  na: 'N/A',
  comparisonBtnLabel: 'Compare to Other Version',
  propertiesSection: 'Properties:',
  actionButtons: {
    download: {
      tooltip: 'Download',
      submitBtn: 'Download to CSV',
      dialogTitle: 'Download Interactions',
      description: 'Select which fields to download',
      sections: {
        data: 'Data',
        properties: {
          general: 'Properties',
          pentest: 'Pentest properties',
          input: 'Built-in properties (input)',
          output: 'Built-in properties (output)',
          custom: 'Custom properties',
          llm: 'LLM properties',
          typesSelected: (selected?: number) => (selected ? `${selected} types selected` : '')
        },
        annotations: 'Annotations',
        topics: 'Topics',
        similarities: 'Similarity Scores'
      }
    },
    copyTooltip: 'Copy to Evaluation',
    deleteTooltip: 'Delete',
    dialogTitle: (isDeletion: boolean) => (isDeletion ? 'Delete Interactions' : 'Copy Interactions to Evaluation'),
    dialogSubmitButtonLabel: 'Ok',
    snackbarMessage: 'Copied!',
    dialogMessage: (selectedSamples: number, isDeletion: boolean, deleteAll?: boolean) =>
      deleteAll
        ? 'You are about to delete all the interactions from the evaluation, please confirm.'
        : isDeletion
          ? `You are about to delete ${selectedSamples} sample${
              selectedSamples > 1 ? 's' : ''
            } from the evaluation, please confirm.`
          : `You are about to copy ${selectedSamples} sample${
              selectedSamples > 1 ? 's' : ''
            } to the evaluation, please confirm.`
  },
  skeletonHeight: '62.2px'
};
