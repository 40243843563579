import React from 'react';

import { Popover, PopoverProps, Stack, styled } from '@mui/material';

export const InteractionTypeDDContainer = styled(Stack)<{ outline?: boolean }>(({ outline, theme }) => ({
  opacity: 1,
  gap: '8px',
  width: '360px',
  border: 'none',
  padding: 'none',
  borderRadius: '4px',
  alignItems: 'center',
  flexDirection: 'row',
  pointerEvents: 'auto',

  '.Mui-disabled': { background: `${theme?.palette?.common?.white} !important` },
  '.MuiButtonBase-root': {
    gap: '4px',
    width: '225px',
    fontWeight: 500,
    boxShadow: 'none',
    overflow: 'hidden',
    margin: '0 0 0 0',
    borderRadius: '6px',
    padding: '8px 12px',
    whiteSpace: 'nowrap',
    justifyContent: 'left',
    textOverflow: 'ellipsis',
    color: theme.palette.grey?.[500],
    border: outline ? `1px solid ${theme.palette.grey?.[300]}` : 'none',
    backgroundColor: outline ? 'transparent' : theme.palette.grey?.[200],

    '&:hover': { boxShadow: 'none' },

    '.MuiButton-endIcon': { marginLeft: 'auto' }
  }
}));

export const StyledInteractionsTypePopover = styled((props: PopoverProps) => (
  <Popover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    slotProps={{
      paper: {
        style: {
          height: 'auto',
          overflow: 'auto',
          padding: '8px 4px',
          maxHeight: '230px',
          borderRadius: '8px'
        }
      }
    }}
    {...props}
  />
))(({ theme }) => ({
  '.MuiButtonBase-root': {
    border: 'none',
    padding: '8px',
    width: '200px',
    boxShadow: 'none',
    textAlign: 'left',
    borderRadius: '2px',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    color: theme.palette.grey?.[400]
  }
}));
