import React, { useState } from 'react';

import { useRouter } from 'next/router';

import dayjs from 'dayjs';

import { SummaryOverview } from './SummaryOverview/SummaryOverview';
import { OverviewNoDataAvailable } from '../OverviewNoDataAvailable/OverviewNoDataAvailable';
import { SummaryAnnotationDistribution } from './SummaryAnnotationDistribution/SummaryAnnotationDistribution';

import { StyledButton, StyledDoughnutChart, StyledSkeleton, StyledText } from '@/components/lib';
import {
  CardContainer,
  StyledTitleContainer,
  SummaryChartContainer,
  SummaryChartAndDistributionContainer,
  SummaryFiltersContainer
} from './Summary.styles';

import { routes } from '@/helpers/routes';
import { getPercentage } from '@/helpers/utils/getPercentage';
import { EnvType, useGetAppVersionProdSummary } from '@/helpers/services/api';

import { constants } from './summary.constants';

interface SummaryProps {
  envType: EnvType;
  interactionType: string;
  selectedVersionId: number;
}

const {
  title,
  latency,
  sessions,
  interactions,
  estAnnotation,
  latencyTooltip,
  skeletonHeight,
  userAnnotation,
  changeViewBtnLabel
} = constants;

export const Summary = (props: SummaryProps) => {
  const { envType, selectedVersionId, interactionType } = props;

  const [isChartView, setIsChartView] = useState(true);
  const [isIncludeEstimated, setIsIncludeEstimated] = useState(true);

  const { push } = useRouter();
  const { data, isLoading } = useGetAppVersionProdSummary(selectedVersionId, {
    environment: envType,
    interaction_type: interactionType
  });

  const curAnnotatedBadNum = isIncludeEstimated ? data?.annotated_bad : data?.user_annotated?.bad;
  const curAnnotatedGoodNum = isIncludeEstimated ? data?.annotated_good : data?.user_annotated?.good;
  const curAnnotatedNum = Number(curAnnotatedBadNum) + Number(curAnnotatedGoodNum);

  const allEstimatedAnnotatedNum = Number(data?.estimated?.bad) + Number(data?.estimated?.good);
  const allUserAnnotatedNum = Number(data?.user_annotated?.bad) + Number(data?.user_annotated?.good);

  const calculatedScore = Number(curAnnotatedGoodNum) / Number(curAnnotatedNum);
  const dataToDisplay = {
    estAnnotation: {
      label: estAnnotation,
      value: getPercentage(allEstimatedAnnotatedNum / Number(data?.n_of_samples), true)
    },
    userAnnotation: {
      label: userAnnotation,
      value: getPercentage(allUserAnnotatedNum / Number(data?.n_of_samples), true)
    },
    interactions: { label: interactions, value: data?.n_of_samples },
    latency: {
      label: latency,
      value: data?.latency_seconds ? `${data?.latency_seconds} Sec` : 'N/A',
      tooltip: latencyTooltip
    },
    sessions: { label: sessions, value: Number(data?.n_of_sessions) }
  };

  const dates =
    data?.start_date && data?.end_date
      ? `${dayjs(data?.start_date).format('lll')} - ${dayjs(data?.end_date).format('lll')}`
      : '';

  const chartData = [
    curAnnotatedBadNum || 0,
    curAnnotatedGoodNum || 0,
    isIncludeEstimated ? Number(data?.unannotated) : allEstimatedAnnotatedNum + Number(data?.unannotated)
  ];

  const handleCenterClick = () => setIsIncludeEstimated(!isIncludeEstimated);

  const handleRedirect = (annotation: string) => {
    annotation === 'unknown'
      ? push(`${routes.samples}?annotations=unknown${isIncludeEstimated ? '%3Bpending' : ''}`)
      : push(
          `${routes.samples}?annotations=${
            isIncludeEstimated ? `${annotation}%2Cestimated%3B` : ''
          }${annotation}%2Cuser_annotated`
        );
  };

  if (isLoading) {
    return (
      <CardContainer>
        <StyledSkeleton height={skeletonHeight} />
        <StyledSkeleton height={skeletonHeight} />
      </CardContainer>
    );
  }

  return (
    <CardContainer data-testid="SummaryContainer">
      <SummaryChartAndDistributionContainer type="card">
        <StyledTitleContainer>
          <StyledText type="h3" contrast="low" text={title} />
          <StyledText type="tinyBold" text={dates} />
        </StyledTitleContainer>
        <SummaryFiltersContainer></SummaryFiltersContainer>
        <SummaryChartContainer>
          {!data?.n_of_samples ? (
            <OverviewNoDataAvailable />
          ) : isChartView ? (
            <StyledDoughnutChart
              data={chartData}
              score={calculatedScore}
              userAnnotated={data?.user_annotated}
              estimatedAnnotated={data?.estimated}
              isIncludeEstimated={isIncludeEstimated}
              pendingAnnotated={Number(data?.pending_annotations)}
              checkboxCheckedIcon="./assets/chart/select-all-checkbox.svg"
              checkboxUnCheckedIcon="./assets/chart/select-all-checkbox-unchecked.svg"
              handleCenterClick={handleCenterClick}
              actions={{
                badRedirect: () => handleRedirect('bad'),
                goodRedirect: () => handleRedirect('good'),
                unannotatedRedirect: () => handleRedirect('unknown')
              }}
            />
          ) : (
            <SummaryAnnotationDistribution summaryData={data} handleRedirect={handleRedirect} />
          )}
        </SummaryChartContainer>
        <StyledButton
          variant="outlined"
          data-testid="ChangeViewBtn"
          label={changeViewBtnLabel(isChartView)}
          onClick={() => setIsChartView(!isChartView)}
        />
      </SummaryChartAndDistributionContainer>
      <SummaryOverview dataToDisplay={dataToDisplay} isPartialDataMode={!data?.latency_seconds} />
    </CardContainer>
  );
};
