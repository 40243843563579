import React, { useState } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormGroup, CheckboxProps as MUICheckboxProps, Stack, useTheme } from '@mui/material';

import { Text } from '../../Text/Text';
import { Input } from '../../Input/Input';

import { MUIStyledCheckbox, sxBorderStyles, sxLabelStyles } from './Checkbox.styles';

export interface CheckboxProps extends MUICheckboxProps {
  limit?: number;
  state: string[];
  formTitle?: string;
  disabled?: boolean;
  noAllCount?: boolean;
  searchField?: boolean;
  defaultValue?: string;
  withSelectAll?: boolean;
  direction?: 'row' | 'column';
  options: {
    value: string;
    label?: string;
    count?: number;
    disabled?: boolean;
  }[];
  setState: (state: string[]) => void;
}

export const Checkbox = (props: CheckboxProps) => {
  const {
    state,
    options,
    formTitle,
    disabled,
    noAllCount,
    searchField,
    withSelectAll,
    direction = 'column',
    limit = options.length,
    setState,
    ...otherProps
  } = props;

  const theme = useTheme();

  const [err, setErr] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const isAllSelected = state.length === options.length;
  const allCount: number = Array?.isArray(options)
    ? options?.reduce((sum, option) => sum + (option?.count ? Number(option?.count) : 0), 0)
    : 0;

  const filteredOptions = options.filter(option => option.label?.toLowerCase().includes(searchInput.toLowerCase()));

  const handleCheckboxClick = (value: string) => {
    setErr(false);

    if (state.includes(value)) {
      setState(state.filter((stateVal: string) => value !== stateVal));
    } else {
      if (state.length === limit) {
        setErr(true);
      } else {
        setState([...state, value]);
      }
    }
  };

  const handleSelectAllClick = () => {
    setErr(false);
    if (state.length === options.length) {
      setState([]);
    } else {
      setState(options.map(option => option.value));
    }
  };

  return (
    <FormControl>
      {formTitle && <Text type="bodyBold" text={formTitle} color={theme.palette.grey[500]} />}
      {searchField && <Input value={searchInput} onChange={e => setSearchInput(e?.target?.value)} searchField />}
      <FormGroup row={direction === 'row'}>
        {withSelectAll && (
          <>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
              <FormControlLabel
                data-testid="SelectAll"
                control={<MUIStyledCheckbox disabled={disabled} checked={isAllSelected} {...otherProps} />}
                label={
                  <Text
                    type="body"
                    margin="2px 0 0 -2px"
                    color={theme.palette.common?.black}
                    text={`Select All${withSelectAll ? ` (${options.length})` : ''}`}
                  />
                }
                onChange={handleSelectAllClick}
              />
              {!noAllCount && allCount ? <Text text={`(${allCount})`} marginLeft="8px" /> : <></>}
            </Stack>
            <Stack sx={sxBorderStyles(theme)} />
          </>
        )}
        <Stack>
          {filteredOptions.map((option, i) => (
            <Stack
              key={i}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              data-testid={`Checkbox-${option.label}-${option.disabled ? 'Disabled' : ''}-${
                state.includes(option.value) ? 'Selected' : ''
              }`}
            >
              <FormControlLabel
                data-testid={option.value}
                value={option.value}
                control={
                  <MUIStyledCheckbox
                    disabled={disabled || option?.disabled}
                    checked={state.includes(option.value)}
                    {...otherProps}
                  />
                }
                label={
                  option.label && <Text text={option.label} type="body" sx={sxLabelStyles(theme, option?.disabled)} />
                }
                onChange={() => handleCheckboxClick(option.value)}
              />
              {typeof option?.count === 'number' ? (
                <Text text={`(${option.count})`} marginLeft="8px" overflow="visible" />
              ) : (
                <></>
              )}
            </Stack>
          ))}
        </Stack>
      </FormGroup>
      {err && (
        <Text
          type="h3"
          text={`Limited to ${limit} selections`}
          color={theme.palette.error?.['main' as keyof typeof theme.palette.error]}
        />
      )}
    </FormControl>
  );
};
