import React, { useState } from 'react';

import { ArrowDropDownRounded } from '@mui/icons-material';

import { SamplesDetailsPropertyCategoricalPopOver } from './SamplesDetailsPropertyCategoricalPopOver/SamplesDetailsPropertyCategoricalPopOver';

import { StyledInput, StyledToolTip } from '@/components/lib';
import {
  StyledValueText,
  StyledValueTextContainer,
  StyledValueCategoricalTextContainer
} from '../SampleDetailsDialogBodyCard.styles';

import { getAppStorage } from '@/helpers/utils/localStorage';
import { UpdatePropertySchema } from '@/helpers/utils/deepCloneUpdates';
import { PropertyDefinitionSchema, PropertyType, updateInteractionCustomProperty } from '@/helpers/services/api';

import { constants } from '../../sampleDetailsBodyProperties.constants';

interface SamplesDetailsPropertyValueProps {
  kind: PropertyType;
  isInputFieldOpen: boolean;
  userInteractionId?: string;
  popupAnchor: HTMLElement | null;
  value: string | number | string[];
  definition?: PropertyDefinitionSchema;
  setIsInputFieldOpen: (isOpen: boolean) => void;
  setPopupAnchor: (anchor: HTMLElement | null) => void;
  updateProperty?: (updateData: UpdatePropertySchema) => void;
}

const { na } = constants;

export const SamplesDetailsPropertyValue = (props: SamplesDetailsPropertyValueProps) => {
  const {
    kind,
    value,
    definition,
    popupAnchor,
    isInputFieldOpen,
    userInteractionId,
    updateProperty,
    setPopupAnchor,
    setIsInputFieldOpen
  } = props;

  const [curValue, setCurValue] = useState(value);
  const [newNumericValue, setNewNumericValue] = useState(value);

  const { versionId } = getAppStorage();

  const isCategoricalValue = Array?.isArray(curValue);

  const handleUpdateCustomProperty = async (categoricalVal?: string[]) => {
    const valToUse = categoricalVal ?? Number(newNumericValue);

    await updateInteractionCustomProperty(versionId, `${userInteractionId}`, {
      value: valToUse,
      property_name: definition?.property_name ?? ''
    })?.then(() => {
      setPopupAnchor(null);
      setCurValue(valToUse);
      setIsInputFieldOpen(false);
      updateProperty &&
        updateProperty({
          value: valToUse,
          propertyType: kind,
          interactionId: userInteractionId ?? '',
          propertyName: definition?.property_name ?? ''
        });
    });
  };

  return (
    <StyledToolTip text={typeof curValue === 'string' ? curValue : ''}>
      <StyledValueTextContainer data-popover-trigger>
        {isInputFieldOpen ? (
          <StyledInput
            value={newNumericValue?.toString()}
            onBlur={() => setIsInputFieldOpen(false)}
            onChange={e => setNewNumericValue(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleUpdateCustomProperty()}
            autoFocus
          />
        ) : isCategoricalValue ? (
          <StyledValueCategoricalTextContainer className="Editable-value">
            <StyledValueText type="h3" text={curValue?.join(', ') || na} />
            <ArrowDropDownRounded />
          </StyledValueCategoricalTextContainer>
        ) : (
          <StyledValueText type="h3" text={curValue} className="Editable-value" />
        )}
        <SamplesDetailsPropertyCategoricalPopOver
          kind={kind}
          curValue={curValue}
          popupAnchor={popupAnchor}
          name={definition?.property_name}
          setPopupAnchor={setPopupAnchor}
          handleUpdateCustomProperty={handleUpdateCustomProperty}
        />
      </StyledValueTextContainer>
    </StyledToolTip>
  );
};
