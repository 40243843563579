import React, { useEffect } from 'react';

import { Radio as MUIRadio, Stack, useTheme } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Text } from '../../Text/Text';

export interface RadioProps {
  state: string | undefined;
  setState: (state: string) => void;
  options: {
    value: string;
    label?: string;
    disabled?: boolean;
    icon?: React.ReactNode;
  }[];
  formTitle?: string;
  disabled?: boolean;
  defaultValue?: string;
  direction?: 'row' | 'column';
}

export const Radio = (props: RadioProps) => {
  const { state, setState, options, formTitle, disabled, defaultValue, direction = 'column' } = props;

  const { palette } = useTheme();

  const row = direction === 'row';

  const labelColor = (value: string) =>
    state === value && palette.primary.main ? palette?.grey[500] : palette?.grey[500];

  useEffect(() => {
    const setDefaultValue = () => defaultValue && setState(defaultValue);

    setDefaultValue();
  }, []);

  return (
    <FormControl>
      {formTitle && <Text type="small" contrast="medium" text={formTitle} />}
      <RadioGroup value={state} defaultValue={defaultValue} row={row}>
        {options.map((option, i) => (
          <FormControlLabel
            key={i}
            value={option.value}
            control={<MUIRadio disabled={disabled} />}
            data-testid={`Radio${option.value}`}
            disabled={option.disabled}
            label={
              <Stack
                gap="4px"
                flexDirection="row"
                alignItems="center"
                sx={{ '.MuiSvgIcon-root': { color: labelColor(option.value) } }}
              >
                {option.icon && option.icon}
                {option.label && (
                  <Text
                    text={option.label}
                    color={labelColor(option.value)}
                    type={state === option.value ? 'bodyBold' : 'body'}
                    margin="0"
                  />
                )}
              </Stack>
            }
            onChange={() => setState(option.value)}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
