import { ReadPropertyConfigSchema } from '../services/api';

import { getSessionStorageItem, sessionStorageKeys } from '../utils/sessionStorage';

type StoragePropertiesType = { [key: number]: { prop_filters: ReadPropertyConfigSchema[] } };

export const useStorageProperties = (appId?: number, interactionType?: string | null) => {
  const propertiesStorage = getSessionStorageItem(sessionStorageKeys.propFilters) as StoragePropertiesType;

  if (appId) {
    const applicationProperties = propertiesStorage?.[appId]?.prop_filters;

    if (interactionType && interactionType !== '') {
      const applicationPropertiesByInteractionType = (applicationProperties || [])?.filter(
        property => property.interaction_type?.toLowerCase() === interactionType?.toLowerCase()
      ) as ReadPropertyConfigSchema[];

      // Return app properties by interaction type
      return {
        appStorageProperties: applicationPropertiesByInteractionType,
        allStorageProperties: (propertiesStorage || {}) as StoragePropertiesType
      };
    } else {
      // Return app properties
      return {
        appStorageProperties: (applicationProperties || []) as ReadPropertyConfigSchema[],
        allStorageProperties: (propertiesStorage || {}) as StoragePropertiesType
      };
    }
  } else {
    // Return all properties
    return {
      appStorageProperties: [] as ReadPropertyConfigSchema[],
      allStorageProperties: (propertiesStorage || {}) as StoragePropertiesType
    };
  }
};
