import React from 'react';

import { useTheme, Box, Stack } from '@mui/material';

import { Text } from '../../Text/Text';
import { ToolTip } from '../ToolTip/ToolTip';

import { sxContainerStyles, sxWrapperStyles } from './PercentageContainer.styles';

interface PercentageContainerProps {
  percentage: number;
  amount?: number;
  maxWidth?: string;
  title?: string;
  border?: boolean;
  chosen?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const PercentageContainer = (props: PercentageContainerProps) => {
  const { title, amount, percentage, chosen, border, maxWidth, disabled, onClick } = props;

  const theme = useTheme();

  const coverageText = `${typeof amount === 'number' ? `${amount} Interactions` : ''} (${!Number(isNaN(percentage)) ? Math.round(Number(percentage) * 100) : '0'}%)`;
  const textColor = chosen ? theme.palette.primary.main : theme.palette.grey[500];
  const titleTextColor = chosen ? theme.palette.primary.main : theme.palette.grey[600];
  const bgColor = chosen ? theme.palette.primary.main : theme.palette.grey[200];
  const gradientPercentage = `${percentage * 100}%`;
  const gradientColorStops = `${theme.palette.grey[200]} ${gradientPercentage}, ${theme.palette.grey[100]} ${gradientPercentage}`;
  const tooltipContent = Number(title?.length) > 20 ? title : '';

  return (
    <Box sx={sxContainerStyles(bgColor, border)} data-testid={`PercentageContainer${title}`}>
      <ToolTip text={tooltipContent}>
        <Box
          data-testid="percentage-container"
          onClick={onClick && !disabled ? onClick : undefined}
          sx={sxWrapperStyles(gradientColorStops, disabled || false, border || false, onClick ? true : false, maxWidth)}
        >
          <Text text={title} color={titleTextColor} type="bodyBold" fontSize={15} whiteSpace="nowrap" />
          <Stack direction="row" justifyContent="space-between">
            <Text text={coverageText} color={textColor} type="tinyBold" />
          </Stack>
        </Box>
      </ToolTip>
    </Box>
  );
};
