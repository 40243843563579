import React from 'react';

import { CheckOutlined } from '@mui/icons-material';
import { alpha, Popover, PopoverProps, Stack, styled } from '@mui/material';

import { StyledText } from '@/components/lib';

import { PerPropertySchema } from '@/helpers/services/api';
import { propsMarkingColor } from '@/helpers/utils/getMarkedText';

export const StyledContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'expand' && prop !== 'property' && prop !== 'clickable'
})<{ expand?: boolean; property?: PerPropertySchema; clickable?: boolean; custom?: boolean }>(
  ({ expand, clickable, property, custom, theme }) => ({
    margin: 0,
    width: '100%',
    minWidth: '145px',
    maxWidth: '210px',
    transition: '0.3s',
    padding: '6px 10px !important',
    borderRadius: '8px',
    justifyContent: 'space-between',
    height: 'auto',
    maxHeight: expand ? '345px' : '65px',
    border: `2px solid ${property ? propsMarkingColor(property) : theme.palette.grey[300]}`,
    backgroundColor: property
      ? alpha(propsMarkingColor(property), 0.1)
      : expand
        ? theme.palette.grey[100]
        : 'transparent',

    '.MuiTypography-root': { whiteSpace: 'noWrap' },
    '.MuiTypography-subtitle2': { fontSize: '14px', lineHeight: '18px' },
    '.MuiTypography-body2 ': { color: expand ? theme.palette.grey[500] : theme.palette.grey[400] },
    '.MuiTypography-subtitle1': { fontSize: '14px', color: theme.palette.grey[500], lineHeight: '16px' },
    '.MuiTypography-h3, .Editable-value': {
      fontWeight: 700,
      margin: '2px 0 0',
      borderRadius: '4px',
      border: '1px solid transparent',

      '.MuiSvgIcon-root': { display: 'none' }
    },

    ':hover > div > svg': { display: 'block' },
    ':hover': clickable
      ? {
          cursor: 'pointer',
          borderColor: theme.palette.grey[400],
          backgroundColor: theme?.palette.grey[200],

          '.MuiTypography-body2': {
            color: theme.palette.grey[500]
          },

          '.Editable-value': {
            width: custom ? '120px' : 'auto',
            borderColor: custom ? theme.palette.grey[500] : 'transparent',

            '.MuiSvgIcon-root': { display: 'block', marginLeft: 'auto' }
          }
        }
      : { borderColor: theme.palette.grey[400] }
  })
);

export const StyledRowContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.MuiTypography-h3': { padding: '0 1px' },
  '.MuiTypography-body1 ': { marginTop: '2px' }
});

export const StyledReasonText = styled(StyledText)(({ theme }) => ({
  overflowY: 'auto',
  margin: '8px 0 12px',
  padding: '8px 2px 0 0',
  whiteSpace: 'pre-wrap !important',
  borderTop: `1px solid ${theme.palette.grey[400]}`,

  '&::-webkit-scrollbar': { width: '5px' },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: theme.palette.grey[300]
  }
}));

export const StyledValueText = styled(StyledText)({
  overflow: 'hidden !important'
});

export const StyledValueCategoricalTextContainer = styled(Stack)({
  alignItems: 'center',
  flexDirection: 'row'
});

export const StyledValueTextContainer = styled(Stack)({
  marginRight: '8px',
  overflow: 'hidden !important',

  '.MuiInputBase-root ': { height: '24px' }
});

export const StyledTitleContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '> svg': {
    display: 'none',
    cursor: 'pointer',
    fill: theme.palette.grey[300],

    ':hover': {
      fill: theme.palette.grey[500]
    }
  }
}));

export const StyledUpdatePropertyPopover = styled((props: PopoverProps) => (
  <Popover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    slotProps={{
      paper: { style: { borderRadius: '16px', padding: '16px', width: '210px', height: '220px', overflowY: 'auto' } }
    }}
    {...props}
  />
))({});

export const StyledCheckIcon = styled(CheckOutlined)(({ theme }) => ({
  padding: '4px',
  cursor: 'pointer',
  borderRadius: '50%',
  fill: theme.palette.grey[100],
  backgroundColor: theme.palette.primary.main
}));
