import { styled, Stack, IconButton } from '@mui/material';

import { StyledText, StyledButton } from '@/components/lib';

export const StyledContainer = styled(Stack)(({ theme }) => ({
  minHeight: '56px',
  padding: '4px 12px',
  marginBottom: '16px',
  alignItems: 'center',
  flexDirection: 'row',
  borderRadius: '8px 8px 8px 0',
  justifyContent: 'space-between',
  background: theme.palette.grey[200]
}));

export const StyledPaginationContainer = styled(Stack)({
  gap: '8px',
  minWidth: '110px',
  flexDirection: 'row',
  alignItems: 'center',

  '.MuiTypography-root': { margin: '0 auto', width: 'fit-content' }
});

export const StyledAnnotationAndPropsWrapper = styled(Stack)(({ theme }) => ({
  gap: '4px',
  overflow: 'auto',
  paddingLeft: '12px',
  paddingBottom: '16px',
  borderLeft: `2px solid ${theme.palette.grey[200]}`,

  '&::-webkit-scrollbar': { width: '7px' },
  '&::-webkit-scrollbar-thumb:hover': { background: theme.palette.grey[500] },
  '&::-webkit-scrollbar-thumb': { backgroundColor: theme.palette.grey[300], borderRadius: '8px' }
}));

export const StyledAnnotationPropsContainer = styled(Stack)({
  gap: '16px',
  width: '210px',
  flexDirection: 'row',
  padding: '6px 0 2px 12px'
});

export const StyledAnnotationCellContainer = styled(Stack)(({ theme }) => ({
  padding: '0 8px',
  cursor: 'pointer',
  maxWidth: '260px',
  borderRadius: '8px',
  marginLeft: '-12px',
  background: theme.palette.common.white,
  border: `2px solid ${theme.palette.grey[300]}`
}));

export const StyledInteractionIdText = styled(StyledText)({
  minHeight: '32px',
  padding: '4px 4px 0',
  wordBreak: 'break-all'
});

export const StyledAnnotationCellText = styled(StyledText)({
  maxWidth: '100%',
  margin: 'auto 0',
  overflow: 'hidden',
  WebkitLineClamp: '2',
  whiteSpace: 'initial',
  display: '-webkit-box',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  fontWeight: 500
});

export const StyledPaginationButton = styled(StyledButton)(({ theme }) => ({
  fontWeight: 700,
  background: theme.palette.grey[300],
  color: theme.palette.grey[600],
  minWidth: '24px',
  height: '24px',
  fontSize: '20px',
  padding: '4px',
  borderRadius: '4px',

  ':hover': {
    background: theme.palette.grey[400]
  }
}));

export const StyledHeaderContainerBase = styled(Stack)(({ theme }) => ({
  gap: '12px',
  flexDirection: 'row',
  alignItems: 'center',

  '.MuiTypography-h2': { color: theme.palette.grey[700], fontSize: '16px' },
  '.MuiSvgIcon-root': { color: theme.palette.grey[400], cursor: 'pointer' }
}));

export const StyledHeaderContainerSecondary = styled(Stack)({
  marginLeft: 'auto',
  flexDirection: 'row',
  gap: '16px'
});

export const StyledIconButton = styled(IconButton)({
  padding: 0
});
