import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { TopicsDialog } from './TopicsDialog/TopicsDialog';
import { OverviewNoDataAvailable } from '../OverviewNoDataAvailable/OverviewNoDataAvailable';

import { StyledPercentageContainer, StyledSkeleton, StyledText } from '@/components/lib';
import {
  TopicsContainer,
  TopicsExpandIcon,
  TopicsHeaderContainer,
  TopicsInnerContainer,
  TopicsInput
} from './Topics.styles';

import { routes } from '@/helpers/routes';
import { isHadron } from '@/helpers/utils/isHadron';
import { getAppStorage } from '@/helpers/utils/localStorage';
import {
  EnvType,
  Status,
  AppVersionTopicSchema,
  getTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost
} from '@/helpers/services/api';

import { constants } from '../overview.constants';

interface TopicsProps {
  envType?: EnvType;
  interactionType?: string;
  selectedVersionId?: number;
  timestamp?: { start: number; end: number };
}

export const Topics = (props: TopicsProps) => {
  const { selectedVersionId, envType: propEnvType, timestamp, interactionType } = props;

  const { push } = useRouter();

  const [input, setInput] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [topicsData, setTopicsData] = useState<AppVersionTopicSchema[]>([]);
  const [calculationInProgress, setCalculationInProgress] = useState<Status>(Status['success']);

  const { type, versionId } = getAppStorage();

  const envType = propEnvType ?? type;
  const isProd = envType === EnvType['PROD'];
  const noTopics = !topicsData?.length && !isFetching;
  const currentVersionId = selectedVersionId ?? versionId;
  const inProgress = Status['success'] !== calculationInProgress && Status['failed'] !== calculationInProgress;

  const topicsCount = Array?.isArray(topicsData)
    ? topicsData?.reduce((sum, topic) => sum + Number(topic?.count), 0)
    : 0;
  const filteredTopics = Array.isArray(topicsData)
    ? topicsData?.filter(topic => topic?.topic_name?.toLowerCase()?.includes(input?.toLowerCase()))
    : [];

  const handleRedirect = (topic?: string) => topic && push(`${routes.samples}?topics=${encodeURIComponent(topic)}`);

  const getTopics = async () => {
    setIsFetching(true);

    await getTopicsApiV1ApplicationVersionsApplicationVersionIdTopicsPost(currentVersionId, {
      start_time_epoch: timestamp?.start,
      end_time_epoch: timestamp?.end,
      interaction_type: interactionType,
      environment: envType
    }).then(data => {
      data?.status && setCalculationInProgress(data?.status);
      if (Array.isArray(data?.topics)) {
        setTopicsData(data?.topics);
      }
    });

    setIsFetching(false);
  };

  useEffect(() => void getTopics(), [envType, currentVersionId, timestamp?.start, timestamp?.end, interactionType]);

  if (isHadron()) {
    return <></>;
  }

  return (
    <TopicsContainer type="card" data-testid="TopicsContainer" hide={!!noTopics}>
      <TopicsHeaderContainer>
        <StyledText text={constants.topics.title} type="h3" contrast="low" />
        <TopicsInput
          value={input}
          onCloseIconClick={() => setInput('')}
          onChange={e => setInput(e.target.value)}
          placeholder={constants?.topics?.inputPlaceholder}
          searchField
        />
        {Number(topicsData?.length) > 8 ? <TopicsExpandIcon onClick={() => setIsDialogOpen(true)} /> : <></>}
      </TopicsHeaderContainer>
      {isFetching ? (
        <TopicsInnerContainer>
          {[...Array(8)].map((_i, index) => (
            <StyledSkeleton key={index} height="65px" width="100%" />
          ))}
        </TopicsInnerContainer>
      ) : !filteredTopics?.length ? (
        <OverviewNoDataAvailable
          type="Topics"
          noUploadDataBtn={inProgress}
          title={constants.topics[inProgress ? 'inProgress' : 'noTopics'].title}
          description={constants.topics[inProgress ? 'inProgress' : 'noTopics'].description}
        />
      ) : (
        <TopicsInnerContainer>
          {filteredTopics
            ?.slice(0, isProd ? 100 : 8)
            ?.map((topic: AppVersionTopicSchema, i: number) => (
              <StyledPercentageContainer
                key={i}
                amount={topic?.count}
                title={topic?.topic_name}
                maxWidth={isProd ? 'auto' : '250px'}
                percentage={Number(topic?.count / topicsCount)}
                onClick={() => handleRedirect(topic?.topic_name)}
              />
            ))}
        </TopicsInnerContainer>
      )}
      <TopicsDialog
        open={isDialogOpen}
        topics={topicsData}
        topicsCount={topicsCount}
        handleRedirect={handleRedirect}
        onClose={() => setIsDialogOpen(false)}
      />
    </TopicsContainer>
  );
};
