export const sessionStorageKeys = {
  propFilters: 'prop_filters',
  mixpanelInit: 'mixpanel_init'
};

export const getSessionStorageItem = (key: string) => {
  if (typeof window !== 'undefined') {
    const item = window.sessionStorage.getItem(key);

    try {
      return item ? JSON.parse(item) : null;
    } catch {
      return null;
    }
  }
};

export const setSessionStorageItem = <T>(key: string, value: T) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};

export const removeSessionStorageItem = (key: string) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.removeItem(key);
  }
};

export const clearSessionStorage = () => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.clear();
  }
};
