import React from 'react';

import dayjs from 'dayjs';

import { ArrowBackIosRounded, ClearRounded } from '@mui/icons-material';

import { SampleDetailsDialogHeaderCopy } from './SampleDetailsDialogHeaderCopy/SampleDetailsDialogHeaderCopy';

import { StyledText } from '@/components/lib';
import {
  StyledContainer,
  StyledIconButton,
  StyledPaginationButton,
  StyledPaginationContainer,
  StyledHeaderContainerBase,
  StyledHeaderContainerSecondary
} from './SampleDetailsDialogHeader.styles';

import { InteractionSchema } from '@/helpers/services/api';
import { getMilliseconds } from '@/helpers/utils/getMilliseconds';

import { constants } from './sampleDetailsDialogHeader.constants';

const { backButtonLabel, nextButtonLabel, paginationText, sessionIdText } = constants;

interface SampleDetailsDialogHeaderProps {
  versionId: number;
  pageCount: number;
  currentPage: number;
  iscComparisonView?: boolean;
  interaction: InteractionSchema;
  isAnnotationInteraction?: boolean;
  closeDialog: () => void;
  setIsComparisonView: (isComparisonView: boolean) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const SampleDetailsDialogHeader = (props: SampleDetailsDialogHeaderProps) => {
  const {
    interaction,
    pageCount,
    currentPage,
    versionId,
    iscComparisonView,
    isAnnotationInteraction,
    closeDialog,
    setCurrentPage,
    setIsComparisonView
  } = props;

  const interactionDate = `${dayjs(interaction?.interaction_datetime).format('lll')} ${getMilliseconds(
    interaction?.started_at,
    interaction?.finished_at
  )}`;

  const handleNextPage = () => setCurrentPage((prev: number) => Math.min(prev + 1, pageCount - 1));

  const handlePrevPage = () => setCurrentPage((prev: number) => Math.max(prev - 1, 0));

  return (
    <StyledContainer>
      <StyledHeaderContainerBase>
        {iscComparisonView && <ArrowBackIosRounded onClick={() => setIsComparisonView(false)} />}
        <StyledText text={sessionIdText(interaction?.session_id)} type="h2" />
        {!isAnnotationInteraction && (
          <SampleDetailsDialogHeaderCopy
            versionId={versionId}
            currentPage={currentPage}
            interactionId={interaction?.user_interaction_id}
          />
        )}
        <StyledText text={interactionDate} type="small" />
      </StyledHeaderContainerBase>
      <StyledHeaderContainerSecondary>
        {!!pageCount && (
          <StyledPaginationContainer>
            <StyledPaginationButton label={backButtonLabel} disabled={currentPage === 0} onClick={handlePrevPage} />
            <StyledText text={paginationText(currentPage, pageCount)} type="bodyBold" />
            <StyledPaginationButton
              label={nextButtonLabel}
              disabled={currentPage === pageCount - 1}
              onClick={handleNextPage}
            />
          </StyledPaginationContainer>
        )}
        <StyledIconButton onClick={closeDialog}>
          <ClearRounded />
        </StyledIconButton>
      </StyledHeaderContainerSecondary>
    </StyledContainer>
  );
};
