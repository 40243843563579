import mixpanel from 'mixpanel-browser';

import Hotjar from '@hotjar/browser';

import { datadogRum } from '@datadog/browser-rum';

import { isHadron } from '../utils/isHadron';
import { setSessionStorageItem } from '../utils/sessionStorage';
import { retrieveBackendVersionApiV1BackendVersionGet } from './api';

import { constants } from './mixPanel';

interface InitServicesProps {
  mail: string;
  name: string;
  deploymentType: string;
}

export const initServices = async (props: InitServicesProps) => {
  const { mail, name, deploymentType } = props;

  const isHadronEnv = deploymentType !== 'standard' || !!isHadron();
  const isLocalEnv = typeof window !== 'undefined' && window?.location?.host?.includes('8000');
  const isNotStaging = typeof window !== 'undefined' && !window?.location?.host?.includes('staging');
  const isNotE2E = !mail?.toLocaleLowerCase()?.includes('end2end') && !mail?.toLocaleLowerCase()?.includes('e2e');

  const ignoreDC = !mail?.includes(constants?.orgsToIgnore?.dc);
  const isTrackable = !isLocalEnv && isNotE2E && isNotStaging && !isHadronEnv;

  // DataDog
  const dataDogToken = process.env.NEXT_PUBLIC_DATADOG_TOKEN;
  const dataDogId = process.env.NEXT_PUBLIC_DATADOG_ID;

  if (dataDogToken && dataDogId && isTrackable) {
    await retrieveBackendVersionApiV1BackendVersionGet().then(data => {
      datadogRum.init({
        trackResources: true,
        trackLongTasks: true,
        site: 'datadoghq.com',
        service: 'llm-client',
        applicationId: dataDogId,
        clientToken: dataDogToken,
        trackUserInteractions: true,
        defaultPrivacyLevel: 'allow',
        sessionReplaySampleRate: 100,
        startSessionReplayRecordingManually: true,
        version: data?.['version' as keyof typeof data] ?? 'N/A'
      });
    });

    datadogRum?.startSessionReplayRecording();
    datadogRum.setUser({ name: mail });
  }

  // Mixpanel
  const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

  if (mixpanelToken && isTrackable && ignoreDC) {
    mixpanel.init(mixpanelToken, { ignore_dnt: true, debug: true, track_pageview: true });
    mixpanel.identify(mail);
    mixpanel?.people?.set({ email: mail, full_name: name });

    setSessionStorageItem('mixpanel_init', true);
  } else {
    setSessionStorageItem('mixpanel_init', false);
  }

  // HotJar
  const hotJarId = process.env.NEXT_PUBLIC_HOTJAR_ID;
  const hotJarVersion = process.env.NEXT_PUBLIC_HOTJAR_VERSION;

  if (hotJarId && hotJarVersion && isTrackable && ignoreDC) {
    Hotjar.init(Number(hotJarId), Number(hotJarVersion), { debug: true });
    Hotjar.identify(mail, { first_name: name });
  }
};
