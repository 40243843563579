import React, { useState } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { Topics } from './Topics/Topics';
import { Summary } from './Summary/Summary';
import { Insights } from './Insights/Insights';
import { Properties } from './Properties/Properties';
import { AppFilters } from '../shared/AppFilters/AppFilters';
import { AnnotationOvertimeGraph } from './AnnotationOvertimeGraph/AnnotationOvertimeGraph';
import { OverviewNoVersionSelected } from './OverviewNoVersionSelected/OverviewNoVersionSelected';

import { StyledContainer } from '@/components/lib';
import { OverviewInnerGrid, OverViewPropertiesContainer, SummaryTopicsContainer } from './Overview.styles';

import { EnvType } from '@/helpers/services/api';
import { getAppStorage } from '@/helpers/utils/localStorage';

export const Overview = () => {
  const { interactionType: interactionTypeStorage } = getAppStorage();

  const [refreshValue, setRefreshValue] = useState(false);
  const [selectedApp, setSelectedApp] = useState({
    appId: 0,
    versionId: 0,
    type: 'EVAL',
    timestamp: { start: NaN, end: NaN },
    interactionType: interactionTypeStorage?.value ?? ''
  });

  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery(breakpoints.up('xl'));

  const selectedAppId = selectedApp?.appId;
  const envType = selectedApp?.type as EnvType;
  const selectedVersionId = selectedApp?.versionId;

  const isProd = envType === EnvType['PROD'];
  const isPenTest = envType === EnvType['PENTEST'];
  const interactionTypeToUse = isProd ? undefined : selectedApp?.interactionType;
  const isAppCalcInProgress = false; // Todo - get this from the BE once its ready

  const refresh = () => {
    const appCopy = { ...selectedApp };

    setRefreshValue(!refreshValue);
    setSelectedApp({
      appId: NaN,
      versionId: NaN,
      type: EnvType['EVAL'],
      timestamp: { start: NaN, end: NaN },
      interactionType: ''
    });

    setTimeout(() => setSelectedApp(appCopy), 100);
  };

  return (
    <StyledContainer>
      <AppFilters
        isOverview={true}
        initialLoading={true}
        refreshValue={refreshValue}
        refresh={refresh}
        setSelectedApp={setSelectedApp}
      />
      {!selectedAppId ? null : selectedVersionId ? (
        <OverviewInnerGrid pentest={isPenTest}>
          <OverViewPropertiesContainer data-testid="OverViewPropertiesContainer">
            {isProd ? (
              <AnnotationOvertimeGraph
                envType={envType}
                timestamp={selectedApp.timestamp}
                selectedVersionId={selectedVersionId}
                isAppCalcInProgress={isAppCalcInProgress}
              />
            ) : (
              <SummaryTopicsContainer>
                <Summary
                  envType={envType}
                  selectedVersionId={selectedVersionId}
                  interactionType={selectedApp?.interactionType}
                />
                <Topics
                  envType={envType}
                  selectedVersionId={selectedVersionId}
                  interactionType={interactionTypeToUse}
                />
              </SummaryTopicsContainer>
            )}
            {!isDesktop && (
              <Insights
                envType={envType}
                selectedVersionId={selectedVersionId}
                interactionType={interactionTypeToUse}
              />
            )}
            <Properties
              envType={envType}
              selectedAppId={selectedAppId}
              isAllInteractionTypes={isProd}
              timestamp={selectedApp?.timestamp}
              selectedVersionId={selectedVersionId}
              interactionType={selectedApp?.interactionType}
            />
          </OverViewPropertiesContainer>
          {isProd ? (
            <Topics
              envType={envType}
              timestamp={selectedApp.timestamp}
              selectedVersionId={selectedVersionId}
              interactionType={interactionTypeToUse}
            />
          ) : isDesktop ? (
            <Insights envType={envType} selectedVersionId={selectedVersionId} interactionType={interactionTypeToUse} />
          ) : (
            <></>
          )}
        </OverviewInnerGrid>
      ) : (
        <OverviewNoVersionSelected isPenTest={isPenTest} />
      )}
    </StyledContainer>
  );
};
