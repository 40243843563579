import React from 'react';

import dayjs from 'dayjs';

import { WarningRounded } from '@mui/icons-material';

import { StyledText } from '@/components/lib';
import {
  SampleDetailsDialogBodySelectionContainer,
  SampleDetailsDialogSelectionHeaderContainer,
  SampleDetailsDialogSelectionsBranchLine,
  SampleDetailsDialogSelectionsRowContainer
} from '../SampleDetailsDialogBody.styles';

import { StepSchema } from '@/helpers/services/api';
import { getMilliseconds } from '@/helpers/utils/getMilliseconds';

import { constants } from '../../../../Samples/SamplesTable/samplesTable.constants';

interface SampleDetailsDialogSelectionProps {
  step?: StepSchema;
  value: string;
  section: string;
  needBorder?: boolean;
  interactionType?: string;
  onlySampleData?: boolean;
  setSection: (section: string) => void;
  checkSectionData: (val: string) => { created?: string; data?: { input?: string; output?: string } };
}

const { sampleKey } = constants;

export const SampleDetailsDialogSelection = (props: SampleDetailsDialogSelectionProps) => {
  const { step, value, section, needBorder, interactionType, onlySampleData, setSection, checkSectionData } = props;

  const { created, data } = checkSectionData(value);

  const isImportant = sampleKey === value;
  const dateToUse = step ? step?.started_at : created;
  const formattedLabel = value?.includes('ID') ? step?.name : value;
  const duration = getMilliseconds(`${step?.started_at}`, `${step?.finished_at}`);
  const stepDate = dateToUse && step ? `${dayjs(dateToUse)?.format('l')} ${duration}` : '';
  const dataToUse = step ? !!step?.input || !!step?.output : !!data?.input || !!data?.output;

  if (dataToUse) {
    return (
      <SampleDetailsDialogSelectionsRowContainer
        only_sample_data={onlySampleData}
        border={needBorder ? 'true' : undefined}
      >
        {!isImportant && <SampleDetailsDialogSelectionsBranchLine />}
        <SampleDetailsDialogBodySelectionContainer
          key={value}
          data-testid={value}
          type={interactionType}
          important={isImportant}
          llm_call_err={!!step?.error}
          selected={value === section}
          only_sample_data={onlySampleData}
          onClick={() => setSection(value)}
        >
          <SampleDetailsDialogSelectionHeaderContainer>
            <WarningRounded fontSize="small" color="warning" />
            <StyledText text={formattedLabel} type="bodyBold" />
          </SampleDetailsDialogSelectionHeaderContainer>
          <StyledText text={interactionType ?? stepDate} type="small" contrast="low" />
        </SampleDetailsDialogBodySelectionContainer>
      </SampleDetailsDialogSelectionsRowContainer>
    );
  }

  return <></>;
};
