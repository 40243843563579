import React from 'react';

import { StyledContainer, StyledText, StyledToolTip } from '@/components/lib';
import { SummaryRowContainer, SummaryScoreBigText, SummaryDetailsContainer } from '../Summary.styles';

import { constants } from '../summary.constants';

interface SummaryOverviewProps {
  isPartialDataMode: boolean;
  dataToDisplay: {
    [key: string]: {
      label: string;
      value: string | number | undefined | null;
      tooltip?: string | null;
    };
  };
}

export const SummaryOverview = (props: SummaryOverviewProps) => {
  const { dataToDisplay, isPartialDataMode } = props;

  return (
    <SummaryDetailsContainer type="card">
      <StyledText text={constants.overview} type="h3" contrast="low" />
      <SummaryRowContainer withBorder isColumn>
        <SummaryRowContainer>
          <StyledContainer>
            <SummaryScoreBigText type="h1" text={dataToDisplay.sessions.value} />
            <StyledText type="tinyBold" text={dataToDisplay.sessions.label} />
          </StyledContainer>
          <StyledContainer>
            <SummaryScoreBigText type="h1" text={dataToDisplay.interactions.value} />
            <StyledText type="tinyBold" text={dataToDisplay.interactions.label} />
          </StyledContainer>
        </SummaryRowContainer>
        <SummaryRowContainer>
          <StyledContainer>
            <SummaryScoreBigText type="h1" text={dataToDisplay.userAnnotation.value} />
            <StyledText type="tinyBold" text={dataToDisplay.userAnnotation.label} />
          </StyledContainer>
          <StyledContainer>
            <SummaryScoreBigText type="h1" text={dataToDisplay.estAnnotation.value} />
            <StyledText type="tinyBold" text={dataToDisplay.estAnnotation.label} />
          </StyledContainer>
        </SummaryRowContainer>
      </SummaryRowContainer>
      {!isPartialDataMode && (
        <SummaryRowContainer withBorder>
          <StyledToolTip text={dataToDisplay.latency.tooltip ?? ''}>
            <StyledContainer>
              <StyledText type="h2" text={dataToDisplay.latency.value} />
              <StyledText type="tinyBold" text={dataToDisplay.latency.label} />
            </StyledContainer>
          </StyledToolTip>
        </SummaryRowContainer>
      )}
    </SummaryDetailsContainer>
  );
};
