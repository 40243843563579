import React from 'react';

import { useRouter } from 'next/router';

import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { StyledText } from '@/components/lib';
import { InsightAnalysisContainer, InsightInnerContainer, InsightViewButton } from '../../Insights.styles';

import { routes } from '@/helpers/routes';
import { buildDrillDownQuery } from '../insight.helpers';
import { DataFilter, InsightSchema } from '@/helpers/services/api';
import { getStorageItem, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';

import { constants } from '../../../overview.constants';

interface AnalysisInsightProps {
  showLess: boolean;
  insight: InsightSchema;
}

const { buttonLabel, analysis, suggestions, properties } = constants.insights.analysis;

export const AnalysisInsight = (props: AnalysisInsightProps) => {
  const { insight, showLess } = props;

  const { push } = useRouter();

  const handleRedirectSamples = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    dataFilters: DataFilter[],
    topics: DataFilter[]
  ) => {
    e?.stopPropagation();

    const appStorage = getStorageItem(storageKeys.app);

    setStorageItem(storageKeys.app, {
      ...appStorage,
      interactionType: { value: insight?.interaction_type_name, options: appStorage?.interactionType?.options }
    });

    push(`${routes.samples}${buildDrillDownQuery(dataFilters, topics)}`);
  };

  return (
    <InsightAnalysisContainer>
      {showLess ? (
        <StyledText text={insight?.additional_text_info?.analysis} type="tiny" />
      ) : (
        <>
          <StyledText text={analysis} type="tinyBold" />
          <StyledText text={insight?.additional_text_info?.analysis} type="tiny" />
          <StyledText text={suggestions} type="tinyBold" />
          <StyledText text={insight?.additional_text_info?.suggestions} type="tiny" />
          <StyledText text={properties} type="tinyBold" />
          <StyledText text={insight?.properties} type="tiny" />
        </>
      )}
      <InsightInnerContainer marginTop="auto">
        {showLess ? <ExpandMore /> : <ExpandLess />}
        <InsightViewButton
          variant="text"
          data-testid={`AnalysisInsightButton${insight?.id}`}
          onClick={e => handleRedirectSamples(e, insight?.segment_filters, insight?.categorical_segment_filters)}
          analysis
        >
          {buttonLabel}
        </InsightViewButton>
      </InsightInnerContainer>
    </InsightAnalysisContainer>
  );
};
