import { Stack, styled } from '@mui/material';

import { StyledButton } from '@/components/lib';
import { paletteOptions } from '@/components/lib/theme/palette';

import { fadeInAnimation } from '@/helpers/animations';

export const SampleDetailsDialogBodyContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'empty'
})<{ empty?: boolean }>(({ empty }) => ({
  gap: '7px',
  padding: '0',
  height: '600px',
  display: 'grid',
  gridTemplateColumns: empty ? 'auto' : '250px auto',

  '@media (max-height: 790px)': { height: '70vh' },
  '@media (max-height: 610px)': { height: '67vh' }
}));

export const SampleDetailsDialogSessionsSelectionContainer = styled(Stack)(({ theme }) => ({
  display: 'flow',
  overflow: 'auto',
  marginTop: '-28px',
  padding: '28px 0 0',
  position: 'relative',

  '&::after': {
    top: '36px',
    right: '0',
    bottom: '0',
    width: '2px',
    content: '""',
    position: 'absolute',
    backgroundColor: theme.palette.grey[200]
  }
}));

export const SampleDetailsDialogSelectionsContainer = styled(Stack)(({ theme }) => ({
  gap: '8px',
  width: 'auto',
  overflow: 'auto',
  position: 'relative',
  margin: '-16px 0 -64px',
  padding: '36px 16px 54px',
  animation: `${fadeInAnimation} 0.5s ease`,
  borderLeft: `2px solid ${theme.palette.grey[200]}`,

  '&::before': {
    left: 0,
    right: 0,
    top: '62px',
    content: '""',
    height: '2px',
    width: '24px',
    position: 'absolute',
    backgroundColor: theme.palette.grey[200]
  },

  ':last-of-type:nth-child(n+2)': { borderLeft: 'none' },

  ':only-child': {
    borderLeft: 'none',

    '&::before': {
      top: 0,
      left: 0,
      content: '""',
      width: '1.5px',
      height: '64px',
      position: 'absolute',
      backgroundColor: theme.palette.grey[200]
    },

    '&::after': {
      left: 0,
      top: '63px',
      width: '24px',
      height: '2px',
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

export const SampleDetailsDialogSelectionsComparisonButton = styled(StyledButton)(({ theme }) => ({
  border: 'none',
  transition: '0.5s',
  fontSize: '13px !important',
  padding: '7px auto !important',
  background: theme.palette.grey[200],

  ':hover': { opacity: 0.8 },
  '&:disabled': { border: 'none', color: theme.palette.grey[400] }
}));

export const SampleDetailsDialogSelectionsInnerContainer = styled(Stack)<{ count?: number }>(({ count, theme }) => ({
  overflow: 'auto',
  marginTop: '-8px',
  paddingTop: '6px',
  position: 'relative',

  '&::after': {
    top: '0px',
    left: '0px',
    content: '""',
    position: 'absolute',
    height: count && count > 0 ? `8px` : 0,
    borderLeft: `2px solid ${theme.palette.grey[200]}`
  }
}));

export const SampleDetailsDialogSelectionsRowContainer = styled(Stack)<{ border?: string; only_sample_data?: boolean }>(
  ({ border, only_sample_data, theme }) => ({
    width: '100%',
    position: 'relative',
    flexDirection: 'row',
    borderLeft: border ? `2px solid ${theme.palette.grey[200]}` : 'none',

    '&:last-child': {
      borderLeft: 'none',

      '&::after': {
        top: '0px',
        left: '0px',
        content: '""',
        height: '28px',
        position: 'absolute',
        borderLeft: only_sample_data ? 'none' : `2px solid ${theme.palette.grey[200]}`
      }
    }
  })
);

interface SampleDetailsDialogBodySelectionContainerProps {
  type?: string;
  selected?: boolean;
  important?: boolean;
  llm_call_err?: boolean;
  only_sample_data?: boolean;
}

export const SampleDetailsDialogBodySelectionContainer = styled(Stack, {
  shouldForwardProp: prop =>
    prop !== 'selected' &&
    prop !== 'llmCallErr' &&
    prop !== 'important' &&
    prop !== 'type' &&
    prop !== 'only_sample_data'
})<SampleDetailsDialogBodySelectionContainerProps>(
  ({ selected, llm_call_err, important, type, only_sample_data, theme }) => ({
    zIndex: 1,
    height: '58px',
    padding: '8px',
    fontWeight: 700,
    cursor: 'pointer',
    transition: '0.3s',
    justifyContent: 'center',
    width: important ? '220px' : '200px',
    borderRadius: important && !only_sample_data ? '8px 8px 8px 0' : '8px',
    background: type ? theme.palette.grey[100] : 'transparent',
    border: selected
      ? `2px solid ${theme.palette.primary.main}`
      : `2px solid ${important ? theme.palette.grey[200] : 'transparent'}`,

    ':hover': {
      background: theme.palette.grey[200],
      borderColor: selected ? theme.palette.primary.main : theme.palette.grey[200]
    },

    '.MuiTypography-body2': {
      textWrap: 'nowrap',
      color: llm_call_err
        ? paletteOptions.severity?.medium
        : selected
          ? theme.palette.primary.main
          : theme.palette.grey[600]
    },

    '.MuiSvgIcon-root': {
      display: llm_call_err ? 'auto' : 'none',
      color: paletteOptions.severity?.medium
    }
  })
);

export const SampleDetailsDialogSelectionHeaderContainer = styled(Stack)({
  gap: '6px',
  flexDirection: 'row'
});

export const SampleDetailsDialogSelectionsBranchLine = styled(Stack)(({ theme }) => ({
  width: '16px',
  height: '2px',
  margin: 'auto 0',
  background: theme.palette.grey[200]
}));
