import React, { useEffect, useState } from 'react';

import { ContentCopy } from '@mui/icons-material';

import { StyledText } from '@/components/lib';
import { StyledContentCopyContainer } from './SampleDetailsDialogHeaderCopy.styles';

import { routes } from '@/helpers/routes';
import { getAppStorage } from '@/helpers/utils/localStorage';

import { constants } from '../sampleDetailsDialogHeader.constants';

interface SampleDetailsDialogHeaderCopyProps {
  versionId: number;
  currentPage: number;
  interactionId: string;
}

const { copyLinkText, linkCopied } = constants.copy;

export const SampleDetailsDialogHeaderCopy = (props: SampleDetailsDialogHeaderCopyProps) => {
  const { interactionId, currentPage, versionId } = props;

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const { appName, versionName, type } = getAppStorage();

  const handleCopyInteractionLink = () => {
    const curUrl = typeof window !== 'undefined' ? window?.location?.origin : 'https://app.llm.deepchecks.com/';
    const copyText = `${curUrl}${routes?.samples}?appName=${encodeURIComponent(
      appName
    )}&versionName=${encodeURIComponent(versionName)}&env=${type}&versionId=${versionId}&search_text=${interactionId}`;

    navigator.clipboard.writeText(copyText);
    setIsLinkCopied(true);
  };

  useEffect(() => {
    if (isLinkCopied) {
      setIsLinkCopied(false);
    }
  }, [currentPage]);

  return (
    <StyledContentCopyContainer
      is_link_copied={isLinkCopied ? 'true' : 'false'}
      data-testid="CopyInteractionLink"
      onClick={handleCopyInteractionLink}
    >
      <ContentCopy fontSize="small" />
      <StyledText text={isLinkCopied ? linkCopied : copyLinkText} type="tiny" />
    </StyledContentCopyContainer>
  );
};
