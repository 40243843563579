import React from 'react';

import { Stack, styled } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { StyledText, StyledDropdown } from '@/components/lib';
import { TextProps } from '@/components/lib/components/Text/Text';

export const StyledContainer = styled(Stack)({
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '24px'
});

export const StyledArrowBackIcon = styled(ArrowBackIosIcon)(({ theme }) => ({
  cursor: 'pointer',
  marginRight: '5px',
  fill: theme.palette.grey[500],
  transition: 'opacity 0.3s ease',

  '&:hover': { opacity: 0.5 }
}));

export const StyledName = styled(StyledText)({
  marginRight: '8px'
});

export const StyledInfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  height: '24px',
  fill: theme.palette.primary.main
}));

const StyledValuesSectionContainerBase = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center'
});

export const StyledValuesContainer = styled(StyledValuesSectionContainerBase)({
  minWidth: '200px',
  marginLeft: '24px',
  marginRight: 'auto',
  justifyContent: 'center'
});

export const StyledValueLabel = styled((props: TextProps) => <StyledText type="tinyBold" {...props} />)(
  ({ theme }) => ({
    color: theme.palette.grey[400]
  })
);

interface StyledValuesProps {
  backgroundColor?: string;
}

export const StyledValues = styled(StyledValuesSectionContainerBase, {
  shouldForwardProp: prop => prop !== 'backgroundColor'
})<StyledValuesProps>(({ theme, backgroundColor }) => ({
  backgroundColor,
  margin: '0 12px',
  padding: '4px 10px',
  borderRadius: '10px',
  justifyContent: 'space-between',
  color: theme.palette.common.white,

  '& svg': { opacity: 0.6, height: '13px' }
}));

export const StyledValue = styled((props: TextProps) => <StyledText type="h1" {...props} />)(({ theme }) => ({
  color: theme.palette.common.white
}));

export const StyledCondition = styled((props: TextProps) => <StyledValue type="h3" {...props} />)({
  opacity: 0.6
});

export const StyledOperator = styled(StyledCondition)({
  margin: '0 2px 0 1px'
});

export const StyledOrderDropdown = styled(StyledDropdown)({
  width: 'auto'
});
