import React, { useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';

import dayjs from 'dayjs';

import { StyledComboChart, StyledLoader, StyledText } from '@/components/lib';
import {
  OvertimeGraphNoDataImage,
  AnnotationOvertimeGraphContainer,
  AnnotationOvertimeGraphHeaderContainer
} from '../OverviewNoVersionSelected/OverviewNoVersionSelected.styles';

import { getPercentage } from '@/helpers/utils/getPercentage';
import {
  EnvType,
  AnnotationsMonitorSchema,
  getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost
} from '@/helpers/services/api';

interface AnnotationOvertimeGraphProps {
  envType: EnvType;
  selectedVersionId: number;
  isAppCalcInProgress: boolean;
  timestamp: { start: number; end: number };
}

const constants = {
  score: ' | Score ',
  title: 'Overtime Status',
  graphStyles: { margin: '0 auto' },
  legends: ['User Annotation', 'Estimated Annotation', 'Unknown'],
  graphSizes: { width: '98% !important', height: '400px !important' },
  inProgressLabel: '* Estimated Annotations are still been calculated, please refresh to see updated data',
  distribution: (interactions?: number, annotations?: string) =>
    `Total: ${interactions} Interactions \n Annotated: ${annotations}`
};

export const OVERTIME_LOADING_DEBOUNCE = 500;
export const OVERTIME_LOADING_TIMEOUT = 1000;

const { title, score, legends, graphStyles, graphSizes, inProgressLabel, distribution } = constants;

export const AnnotationOvertimeGraph = (props: AnnotationOvertimeGraphProps) => {
  const { envType, timestamp, selectedVersionId, isAppCalcInProgress } = props;

  const { push } = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [annotationMonitorData, setAnnotationMonitorData] = useState<AnnotationsMonitorSchema>();

  const fetchAnnotationsDebounceRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setIsLoading(true);

    const fetchAnnotations = () => {
      getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost(selectedVersionId, {
        environment: envType,
        end_time_epoch: timestamp.end || dayjs().unix(),
        start_time_epoch: timestamp.start || dayjs().subtract(1, 'month').unix(),
        tz: `${Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone}`
      }).then(res => {
        setAnnotationMonitorData(res);

        const timedOutLoading = setTimeout(() => setIsLoading(false), OVERTIME_LOADING_TIMEOUT);

        () => clearTimeout(timedOutLoading);
      });
    };

    fetchAnnotationsDebounceRef?.current && clearTimeout(fetchAnnotationsDebounceRef?.current);
    fetchAnnotationsDebounceRef.current = setTimeout(() => fetchAnnotations(), OVERTIME_LOADING_DEBOUNCE);

    return () => {
      fetchAnnotationsDebounceRef?.current && clearTimeout(fetchAnnotationsDebounceRef?.current);
    };
  }, [envType, timestamp?.start, timestamp?.end, selectedVersionId]);

  const labels = Array?.isArray(annotationMonitorData?.window_list)
    ? annotationMonitorData?.window_list?.map(window =>
        dayjs(window).format(annotationMonitorData?.frequency === 'HOUR' ? 'DD/MM LT' : 'll')
      )
    : [];

  const barData = Array.isArray(annotationMonitorData?.values)
    ? annotationMonitorData?.values?.map(val => [
        Number(val?.per_type_results?.good?.user?.amount || 0) + Number(val?.per_type_results?.bad?.user?.amount || 0),
        Number(val?.per_type_results?.good?.estimated?.amount || 0) +
          Number(val?.per_type_results?.bad?.estimated?.amount || 0),
        Number(val?.per_type_results?.unknown?.user?.amount || 0) +
          Number(val?.per_type_results?.unknown?.estimated?.amount || 0)
      ])
    : [];

  const lineData = Array.isArray(annotationMonitorData?.values)
    ? annotationMonitorData?.values?.map(val => Number(val?.score))
    : [];

  const totalInteractionsCount = barData?.reduce((acc, val) => acc + val.reduce((acc, val) => acc + val, 0), 0);
  const totalGoodInteractionsCount = annotationMonitorData?.values?.reduce(
    (acc, val) =>
      acc +
      (Number(val?.per_type_results?.good?.user?.amount || 0) +
        Number(val?.per_type_results?.good?.estimated?.amount || 0)),
    0
  );

  const totalUserAnnotationsCount = annotationMonitorData?.values?.reduce(
    (acc, val) =>
      acc +
      (Number(val?.per_type_results?.good?.user?.amount || 0) + Number(val?.per_type_results?.bad?.user?.amount || 0)),
    0
  );

  const isNoData = lineData?.every(val => Number.isNaN(val));
  const totalScore = getPercentage(Number(totalGoodInteractionsCount) / totalInteractionsCount);
  const annotationsPercentage = getPercentage(Number(totalUserAnnotationsCount) / totalInteractionsCount);

  const dataInfoLabel = isNoData ? (
    isAppCalcInProgress ? (
      inProgressLabel
    ) : (
      ''
    )
  ) : (
    <>
      {distribution(totalInteractionsCount, annotationsPercentage)}
      <span>
        {score} {totalScore}
      </span>
    </>
  );

  return (
    <AnnotationOvertimeGraphContainer type="card">
      <AnnotationOvertimeGraphHeaderContainer>
        <StyledText text={title} type="h3" contrast="low" />
        <StyledText
          type="small"
          contrast="low"
          text={dataInfoLabel}
          data-testid="InteractionAnnotationOvertimeGraphContainer"
        />
      </AnnotationOvertimeGraphHeaderContainer>
      {isLoading ? (
        <StyledLoader />
      ) : (
        <>
          {isNoData && <OvertimeGraphNoDataImage />}
          <StyledComboChart
            labels={labels}
            barData={barData}
            lineData={lineData}
            barLegends={legends}
            style={graphStyles}
            barWidth={graphSizes.width}
            barHeight={graphSizes.height}
            rawData={annotationMonitorData?.values}
            handleRedirect={push}
          />
        </>
      )}
    </AnnotationOvertimeGraphContainer>
  );
};
