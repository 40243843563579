import React, { useEffect, useState } from 'react';

import { alpha, Stack } from '@mui/material';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material';

import { StyledButton, StyledText } from '@/components/lib';
import { InteractionTypeDDContainer, StyledInteractionsTypePopover } from './InteractionTypeDropDown.styles';

import { ReadInteractionTypeSchema } from '@/helpers/services/api';
import { getStorageItem, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';

import { constants } from './interactionTypes.constants';

interface InteractionTypeDropDownProps {
  noLabel?: boolean;
  outline?: boolean;
  interactionType: string;
  appInteractionTypes?: ReadInteractionTypeSchema[];
  additionalCallback?: () => void;
  setInteractionType: (value: string) => void;
}

const { label, types } = constants;

export const InteractionTypeDropDown = (props: InteractionTypeDropDownProps) => {
  const { noLabel, outline, interactionType, appInteractionTypes, additionalCallback, setInteractionType } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const appStorage = getStorageItem(storageKeys.app);

  const availableTypesToUse = appInteractionTypes || appStorage?.interactionType?.options;

  const availableTypes = (() => {
    const availableTypesStorageNames = Array?.isArray(availableTypesToUse)
      ? availableTypesToUse?.filter(val => !!val?.is_active)?.map((type: { name: string }) => type?.name?.toLowerCase())
      : [];

    return availableTypesStorageNames?.length
      ? types?.filter(type => availableTypesStorageNames?.includes(type?.name.toLowerCase()))
      : types;
  })();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(anchorEl ? null : event.currentTarget);

  const handleChooseType = (type: string) => {
    if (type !== interactionType) {
      setAnchorEl(null);
      setInteractionType(type);
      setStorageItem(storageKeys.app, {
        ...appStorage,
        interactionType: { options: availableTypesToUse, value: type }
      });

      additionalCallback && additionalCallback();
    } else {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (
      !interactionType ||
      !availableTypes?.find(type => type?.name?.toLowerCase() === interactionType?.toLowerCase())
    ) {
      setInteractionType(availableTypes[0]?.name);
      setStorageItem(storageKeys.app, {
        ...appStorage,
        interactionType: {
          value: availableTypes[0]?.name,
          options: availableTypesToUse
        }
      });
    }
  }, [interactionType, appInteractionTypes]);

  return (
    <>
      <InteractionTypeDDContainer outline={!!outline}>
        {!noLabel && <StyledText text={label} type="bodyBold" />}
        <StyledButton
          endIcon={
            anchorEl ? <KeyboardArrowUpOutlined fontSize="large" /> : <KeyboardArrowDownOutlined fontSize="large" />
          }
          label={interactionType}
          startIcon={availableTypes.find(type => type?.name === interactionType)?.icon}
          onClick={handleOpen}
        />
      </InteractionTypeDDContainer>
      <StyledInteractionsTypePopover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <Stack>
          {availableTypes?.map(type => (
            <StyledButton
              key={type.id}
              label={type.name}
              startIcon={type.icon}
              sx={{
                padding: '8px 12px !important',
                color:
                  type.name?.toLowerCase() === interactionType?.toLowerCase() ? `${type?.color} !important` : 'inherit',
                background:
                  type.name?.toLowerCase() === interactionType?.toLowerCase()
                    ? `${alpha(type?.color, 0.2)} !important`
                    : 'transparent',

                '&:hover': { background: `${alpha(type?.color, 0.2)} !important`, boxShadow: 'none' }
              }}
              onClick={() => handleChooseType(type?.name)}
            />
          ))}
        </Stack>
      </StyledInteractionsTypePopover>
    </>
  );
};
