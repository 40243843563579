import { Stack, styled } from '@mui/material';

import { StyledButton, StyledContainer, StyledText } from '@/components/lib';

interface SummaryContainerProps {
  withBorder?: boolean;
  isColumn?: boolean;
}

export const SummaryContainer = styled(StyledContainer)({
  gap: '24px',
  padding: '24px'
});

export const SummaryFiltersContainer = styled(Stack)({
  flexWrap: 'wrap',
  flexDirection: 'row'
});

export const SummaryChartAndDistributionContainer = styled(StyledContainer)({
  gap: 0,
  height: '100%',
  minWidth: '400px',

  '.MuiButton-outlined': {
    padding: '0',
    border: 'none',
    width: 'fit-content',
    margin: '12px auto 0',

    ':hover': { border: 'none' }
  }
});

export const SummaryDetailsContainer = styled(StyledContainer)({
  gap: '24px',
  height: '100%',
  padding: '12px 16px'
});

export const StyledTitleContainer = styled(Stack)({
  gap: '8px',
  flexDirection: 'row',
  marginBottom: 'auto',
  alignItems: 'flex-end'
});

export const SummaryChartContainer = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'chart'
})<{ chart?: boolean }>(({ chart }) => ({
  padding: '0',
  minHeight: '260px',
  margin: '0 auto auto',
  width: chart ? '250px' : 'auto'
}));

export const SummaryScoreBigText = styled(StyledText)({
  fontSize: '32px',
  fontWeight: 400
});

export const SummaryRowContainer = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'withBorder' && prop !== 'isColumn'
})<SummaryContainerProps>(({ withBorder, isColumn, theme }) => ({
  padding: '0 4px',
  margin: '0 auto',
  maxWidth: '350px',
  textAlign: 'center',
  borderRadius: '8px',
  alignItems: 'center',
  flexDirection: isColumn ? 'column' : 'row',
  justifyContent: withBorder ? 'center' : 'space-between',
  border: `2px solid ${withBorder ? theme.palette.grey[200] : 'none'}`
}));

export const CardContainer = styled(StyledContainer)({
  padding: 0,
  gap: '24px',
  display: 'grid',
  minHeight: '418px',
  alignItems: 'center',
  borderRadius: '16px',
  gridTemplateColumns: 'auto auto',

  '@media (max-width: 750px)': {
    flexWrap: 'wrap'
  }
});

export const CardDescriptionContainer = styled(StyledContainer)({
  height: '100%'
});

export const CardButton = styled(StyledButton)({
  maxWidth: '250px',
  gap: '16px',
  marginTop: 'auto'
});

export const SummaryUsersOnlyText = styled(StyledText)(({ theme }) => ({
  width: '100%',
  margin: '0 auto',
  padding: '16px 0',
  maxWidth: '350px',
  borderRadius: '8px',
  textAlign: 'center',
  border: `2px solid ${theme.palette.grey[200]}`
}));
