import React, { useState, useEffect } from 'react';

import { useRouter } from 'next/router';

import { StackProps } from '@mui/material';

import { PropertyDrillDownDialog } from './PropertyDrillDownDialog/PropertyDrillDownDialog';
import { PropertyDrillDownHeader } from './PropertyDrillDownHeader/PropertyDrillDownHeader';
import { PropertyDrillDownInteractions } from './PropertyDrillDownInteractions/PropertyDrillDownInteractions';
import { PropertyDrillDownDistribution } from './PropertyDrillDownDistribution/PropertyDrillDownDistribution';
import { PropertyDrillDownProductionChart } from './PropertyDrillDownOvertimeChart/PropertyDrillDownOvertimeChart';
import { PropertyDrillDownCategoricalSelections } from './PropertyDrillDownCategoricalSelections/PropertyDrillDownCategoricalSelections';

import { StyledContainer, StyledContentContainer, StyledSeeAllSamplesButton } from './PropertyDrillDown.styles';

import { routes } from '@/helpers/routes';
import { getStorageItem, setStorageItem, storageKeys } from '@/helpers/utils/localStorage';
import {
  EnvType,
  OrderByDirection,
  CategoryPropertyValue,
  ReadPropertyConfigSchema,
  PropertyDefinitionSchema,
  GetInteractionsPerPropertyApiV1InteractionsPerPropertyGet200,
  useGetInteractionsPerPropertyApiV1InteractionsPerPropertyGet,
  useListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet
} from '@/helpers/services/api';

import { constants } from './propertyDrillDown.constants';

interface PropertyDrillDownProps extends StackProps {
  versionId: number;
  selectedEnv: string;
  propertyInfo: PropertyDefinitionSchema;
  timestamp: { start: number; end: number };
  currentProperty: ReadPropertyConfigSchema;
  listOfProperties: ReadPropertyConfigSchema[];
  score: number | CategoryPropertyValue[] | undefined;
  setCurrentProperty: React.Dispatch<ReadPropertyConfigSchema | null>;
}

const { highestValues, buttonLabel, limit } = constants;

export const PropertyDrillDown = (props: PropertyDrillDownProps) => {
  const {
    score,
    versionId,
    timestamp,
    selectedEnv,
    propertyInfo,
    currentProperty,
    listOfProperties,
    setCurrentProperty,
    ...otherProps
  } = props;
  const { text_property, id, kind, interaction_type } = currentProperty;

  const isProd = selectedEnv === EnvType['PROD'];
  const isCategorical = typeof score === 'object';

  const [orderType, setOrderType] = useState(highestValues);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [currentInteractionId, setCurrentInteractionId] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState(isCategorical ? score[0].name : undefined);
  const [currentData, setCurrentData] = useState<GetInteractionsPerPropertyApiV1InteractionsPerPropertyGet200>();

  const { pathname, query, push, replace } = useRouter();

  const { data, isFetching } = useGetInteractionsPerPropertyApiV1InteractionsPerPropertyGet({
    limit,
    property_config_id: id,
    app_version_id: versionId,
    order_by_desc: orderType === highestValues,
    only_none_category_filter: selectedCategory === null,
    string_category_filter: selectedCategory === null ? undefined : selectedCategory
  });

  const { data: headerData, isFetching: isFetchingHeaderData } =
    useListPropertiesDataApiV1ApplicationVersionsAppVersionIdPropertiesConfigDataGet(versionId, {
      interaction_type: interaction_type
    });

  const handleRedirect = () => {
    const direction = orderType === highestValues ? OrderByDirection['desc'] : OrderByDirection['asc'];

    const appStorage = getStorageItem(storageKeys.app);

    setStorageItem(storageKeys.app, {
      ...appStorage,
      interactionType: { value: interaction_type, options: appStorage?.interactionType?.options }
    });

    push({
      pathname: routes.samples,
      query: {
        model: `${kind}_properties`,
        column: text_property,
        direction: direction,
        ...(isCategorical && {
          insight_conditions: [
            `${currentProperty.text_property},${currentProperty?.condition || 'OVERLAP'},${currentProperty.kind},${
              selectedCategory === null ? '\x00' : selectedCategory
            }`
          ]
        })
      }
    });
  };

  const handleOpenDialog = (interactionId: string) => {
    setDetailsDialogOpen(true);
    setCurrentInteractionId(interactionId);
  };

  const handleCloseDialog = () => {
    setDetailsDialogOpen(false);

    setTimeout(() => setCurrentInteractionId(null), 100);
  };

  const updateAnnotation = (
    interactionId: string,
    annotation: string,
    reason: string,
    isEstimated?: boolean,
    deleteAnnotation?: boolean
  ) => {
    const curEnvInteractions = Array.isArray(currentData?.[selectedEnv])
      ? currentData?.[selectedEnv].map(interaction =>
          interaction.user_interaction_id === interactionId
            ? {
                ...interaction,
                annotation: deleteAnnotation
                  ? undefined
                  : {
                      updated_at: interaction?.annotation?.updated_at ?? '',
                      created_at: interaction?.annotation?.created_at ?? '',
                      is_estimated: isEstimated ? true : false,
                      value: annotation,
                      reason: reason
                    }
              }
            : interaction
        )
      : [];

    setCurrentData({
      ...currentData,
      [selectedEnv]: curEnvInteractions
    } as GetInteractionsPerPropertyApiV1InteractionsPerPropertyGet200);
  };

  useEffect(() => {
    if (data) {
      setCurrentData(data);

      const updatedQuery = { ...query };

      delete updatedQuery.message;
      delete updatedQuery.message_type;

      replace({ pathname, query: updatedQuery }, undefined, {});
    }
  }, [data]);

  return (
    <StyledContainer {...otherProps}>
      <PropertyDrillDownHeader
        name={text_property}
        orderType={orderType}
        headerData={headerData}
        selectedEnv={selectedEnv}
        propertyInfo={propertyInfo}
        isCategorical={isCategorical}
        isFetching={isFetchingHeaderData}
        currentProperty={currentProperty}
        data-testid="PropertyDrillDownHeader"
        isDataAvailable={!!currentData?.[selectedEnv]}
        setOrderType={setOrderType}
        handleRedirectToSamples={handleRedirect}
        setCurrentProperty={setCurrentProperty}
      />
      {isProd && !isCategorical ? (
        <PropertyDrillDownProductionChart
          isProd={isProd}
          timestamp={timestamp}
          versionId={versionId}
          selectedEnv={selectedEnv}
          propertyInfo={propertyInfo}
        />
      ) : (
        <StyledContentContainer categorical={isCategorical}>
          {isCategorical ? (
            <PropertyDrillDownCategoricalSelections
              categories={score}
              timestamp={timestamp}
              selectedCategory={selectedCategory as string}
              interactionType={interaction_type ?? undefined}
              setSelectedCategory={setSelectedCategory}
            />
          ) : (
            currentProperty?.text_property && (
              <PropertyDrillDownDistribution
                headerData={headerData}
                currentProperty={currentProperty}
                selectedEnv={selectedEnv}
                versionId={versionId}
              />
            )
          )}
          <PropertyDrillDownInteractions
            name={text_property}
            isFetching={isFetching}
            isCategorical={isCategorical}
            data={currentData?.[selectedEnv]}
            handleOpenDialog={handleOpenDialog}
          />
        </StyledContentContainer>
      )}
      {isProd ? <></> : <StyledSeeAllSamplesButton label={buttonLabel} onClick={handleRedirect} variant="text" />}
      {currentInteractionId && (
        <PropertyDrillDownDialog
          currentInteractionId={currentInteractionId}
          open={detailsDialogOpen}
          closeDialog={handleCloseDialog}
          updateAnnotation={updateAnnotation}
          listOfProperties={listOfProperties}
        />
      )}
    </StyledContainer>
  );
};
