export const sxContainerStyles = (bgColor: string, border?: boolean) => ({
  border: border ? `2px solid ${bgColor}` : `1px solid ${bgColor}`,
  borderRadius: '8px'
});

export const sxWrapperStyles = (
  gradientColorStops: string,
  disabled: boolean,
  border: boolean,
  isOnClick: boolean,
  maxWidth?: string
) => ({
  background: `linear-gradient(to right, ${gradientColorStops})`,
  cursor: disabled ? 'not-allowed' : isOnClick ? 'pointer' : 'auto',
  maxWidth: maxWidth ?? 250,
  padding: '12px',
  transform: '0.5s',
  gap: '8px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: border ? '6px' : '8px',
  opacity: disabled ? 0.5 : 1,

  ':hover': { opacity: 0.7, '.edit-container': { opacity: 1 } }
});
